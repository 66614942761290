const { ActivationPopupManager } = require("./ActivationPopupManager");
const { PRODUCTS, FAMILIES, API_ENDPOINTS} = require("../consts");
const Hasher = require("../utils/hasher");

module.exports = class ActivationPopupService {
  constructor(sdk, { activationPopupAvailability, activationPopupRenderer, config }) {
    this.sdk = sdk;
    this.activationPopupAvailability = activationPopupAvailability;
    this.activationPopupRenderer = activationPopupRenderer;

    this.activationPopupManager = new ActivationPopupManager({
      minDays: config.minDays,
      maxDays: config.maxDays,
    });

    this.sourceProduct = this.sdk.targetProduct;
  }

  async start({ token }) {
    if (!this.activationPopupAvailability.isAvailable()) {
      console.log("ActivationPopupService: unavailable");
      return;
    }

    const cupidAccount = await this.#getCupidAccount({ token });

    if (!cupidAccount) {
      console.log("ActivationPopupService: unable to get cupid account details");
      return;
    }

    const crossToken = await this.sdk.crossTokenManager.getCrossTokenV2({
      sourceProduct: this.sourceProduct,
      sourceToken: token,
    });

    if (!crossToken) {
      console.log("ActivationPopupService: unable to get cross token");
      return;
    }

    const cpAccount = await this.#getEligibleCPAccount({ crossToken, cupidAccount })

    if (!cpAccount) {
        console.log("ActivationPopupService: no account to show");
        return;
    }

    const canShow = this.activationPopupManager.canShow(cpAccount.product);

    if (canShow) {
      await this.sdk.localization.loadNamespace('activation-popup')
      this.activationPopupRenderer.render({
        cpAccount,
        cupidAccount
      });
      this.activationPopupManager.recordShow(cpAccount.product);
    } else {
      console.log("ActivationPopupService: too soon to show");
    }
  }

  async #getCupidAccount ({ token }) {
    try {
      const langPrefix = window.location.pathname.split('/')[1] || 'en'
      return await this.sdk.api.getCupidAccountDetails({
        apiHost: `${API_ENDPOINTS[this.sdk.targetProduct]}/${langPrefix}/`,
        token
      });
    } catch (error) {
      console.error("ActivationPopupService: Error getting cupid account:", error);
      return null
    }
  }

  async #getEligibleCPAccount ({ cupidAccount, crossToken }) {
    try {
      const emailHash = await Hasher.hash(cupidAccount.email)
      const accounts = await this.sdk.api.getAccountsFromEmailHash({
        emailHash,
        crossToken,
        fields: ['lastDebit', 'unreadLetters']
      });

      const cpAccounts = accounts.filter(account => PRODUCTS[FAMILIES.CP].includes(account.product))
      const chain = this.#getUserChain(cupidAccount.gender, cupidAccount.searchCriteria?.gender)

      return cpAccounts
        .filter(account => account['trial-active'] === 1 && account['payer-nonzero'] === 0)
        .filter(account => account.unreadLetters > 0)
        .sort((a, b) => this.#compareByChain(a, b, chain))
        .sort((a,b) => new Date(b.lastDebit ?? 0) - new Date(a.lastDebit ?? 0))[0]
    } catch (error) {
      console.error("ActivationPopupService: Error getting CP accounts:", error);
      return null
    }
  }

  #compareByChain (a, b, chain) {
    const aIndex = chain.indexOf(a.product);
    const bIndex = chain.indexOf(b.product);
    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  }

  #getUserChain (gender, targetGender) {
    const chains = {
      'mal-fem': [
        'mature',
        'eurodate',
        'travel',
        'china',
        'slavic1',
        'euro',
        'arabia',
        'latin',
        'pina'
      ],
      'fem-mal': [
        'mature',
        'eurodate',
        'china',
        'latin',
        'arabia',
        'travel',
        'euro',
        'slavic1',
      ],
      'fem-fem': [
        'mature',
        'eurodate',
        'pink'
      ],
      'mal-mal': [
        'mature',
        'eurodate',
        'guys',
      ]
    }

    switch (gender) {
      case 'Male':
        switch (targetGender) {
          case 'Male':
            return chains['mal-mal']
          default:
            return chains['mal-fem']
        }
      case 'Female':
        switch (targetGender) {
          case 'Female':
            return chains['fem-fem']
          default:
            return chains['fem-mal']
        }
      default:
        return chains['mal-fem']
    }
  }
}

