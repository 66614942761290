class Localization {
  constructor({ language, xeroxHost }) {
    this.language = language;
    this.xeroxHost = xeroxHost || "https://xerox.clickocean.io/";
    this.state = {};
  }

  async loadNamespace(namespace, language = this.language) {
    try {
      const translations = await this.getTranslations({ namespace, language: language ?? this.language });
      this.state[namespace] = translations;
      return translations;
    } catch (error) {
      if (language !== 'en') {
        return this.loadNamespace(namespace, 'en');
      } else {
        throw new Error(error);
      }
    }
  }

  async getTranslations({ namespace, language }) {
    const response = await fetch(`${this.xeroxHost}sdk/${namespace}/texts/${language}.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(
        `Failed to get ${language} translations for ${namespace}`
      );
    }

    const body = await response.json();
    return body;
  }

  getLocalizedString(namespace, key, data = {}) {
    let translationKey = key;

    if (data.hasOwnProperty('_count') || data.hasOwnProperty('count')) {
      const count = data._count ?? data.count;
      const pluralRules = new Intl.PluralRules(this.language);
      const category = pluralRules.select(count);
      const keyWithCategory = `${key}_${category}`;

      if (this.state[namespace] && this.state[namespace][keyWithCategory]) {
        translationKey = keyWithCategory;
      }
    }

    const template = this.state[namespace] ? this.state[namespace][translationKey] : '';

    return template.replace(/{(\w+)}/g, (_, key) => data[key] !== undefined ? data[key] : '');
  }
}

module.exports = Localization;
