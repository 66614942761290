const { getABtestGroup } = require("../utils/experiments");
const { getPageLanguage } = require('/src/sdk/utils');

const CM_EXPERIMENT_NAME = 'web_activation_popup_coreg'

class ActivationPopupAvailability {
  constructor(sdk) {
    this.sdk = sdk;
  }

  isAvailable() {
      throw new Error('isAvailable must be implemented');
  }
}

class CupidActivationPopupAvailability extends ActivationPopupAvailability {
  productsWithoutActivationPopupEnabled = [
    "pc",
    "elc",
    "sl"
  ];

  isAvailable() {
    if (this.productsWithoutActivationPopupEnabled.includes(this.sdk.targetProduct)) return false;
    if (!window.config?.customerID) return false

    const lang = getPageLanguage({ family: 'CM' })
    if (lang !== 'en') return false

    const experimentGroup = getABtestGroup(
        window.config.customerID,
        CM_EXPERIMENT_NAME,
        50
    )

    return experimentGroup === 'test';
  }
}

module.exports = {
  CupidActivationPopupAvailability,
  CM_EXPERIMENT_NAME
}
